.mailerlite__popup{
    position: fixed;
    bottom:0;
    z-index: 10;
    transform: translateY(22rem);
    transition: transform .3s;
    opacity: 0;
    visibility: hidden;
    width: 100vw;
    &.--active{
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }

    .mailerlite-close{
        cursor: pointer;
        position: absolute;
        right: 2rem;
        top: .6rem;
        height: 2.6rem;
        width: 2.6rem;
        z-index: 1;
    }
}
