footer {
    .footer {
        background: color(base, DefaultFontColor);
        padding: 0 2rem;

        h5 {
            color: color(base, White);
            font-size: 1.8rem;
            line-height: 3.2rem;
            font-weight: 900;
            text-transform: uppercase;
        }

        .footer__links {
            padding: 6rem 0;

            ul a {
                @include transition();
                display: inline-flex;
                color: color(base, White);
                font-size: 1.6rem;
                line-height: 3.2rem;
                background-repeat: no-repeat;
                background-position: left center;
                background-size: 1rem;

                @include hover() {
                    padding: 0 0 0 1.5rem;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='3' stroke='#{color(base, White)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M8.25 4.5l7.5 7.5-7.5 7.5' /%3E%3C/svg%3E%0A");
                }
            }
        }

        .footer__logo {
            display: flex;
            justify-content: flex-end;

            a {
                display: block;
            }
        }
    }

    .absolute__footer {
        padding: 2rem 0;
        background: color(base, Black);

        > div {
            display: flex;
            flex-flow: column;
            align-items: center;

            a {
                color: color(base, White);
                font-size: 1.4rem;
                line-height: 2.4rem;

                @include hover() {
                    text-decoration: underline;
                }
            }

            p {
                a:not(:last-child)::after {
                    content: '|';
                    display: inline-block;
                    margin: 0 .8rem;
                }
            }
        }
    }
}
