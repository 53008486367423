.catalog-product-page_body {
    .product__specifications {
        margin: 0;
        padding: 0;

        h3 {
            display: none;
        }


        .spec__list {
            background: none;
            padding: 0;
            min-height: auto;

            &.--technical {
                background: none;
            }

            h4 {
                font-style: italic;
                display: inline-block;

                &::after {
                    content: '';
                    display: block;
                    @include rev-counter();
                    margin-bottom: 1.2rem;
                }
            }

            @include hover() {
                h4::after {
                    @include rev-counter-hover();
                }
            }

            .attribute {
                padding-left: 4.8rem;
                display: flex;
                flex-direction: row;
            }

            .--divider {
                display: none;
            }

            .spec__collapse-trigger {
                background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="18" width="18" viewBox="0 0 512 512"><path fill="%231e1e1e" d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>');
                background-repeat: no-repeat;
                background-position: 0 1rem;
                padding-left: 3rem;
                cursor: pointer;

                &.open {
                    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="18" width="18" viewBox="0 0 512 512"><path fill="%231e1e1e" d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"/></svg>');
                }
            }

            .spec__collapse-target {
                padding-left: 3rem;
                max-height: 0;
                opacity: 0;
                overflow: hidden;
                transition: max-height 0.5s ease, opacity 0.5s ease;
                display: flex;
                flex-wrap: wrap;

                &.open {
                    max-height: 150rem;
                    opacity: 1;
                }
            }

            .--spec {
                width: 50%;
                padding-right: 2rem;
                @include breakpoint(small only) {
                    width: 100%;
                    padding-right: 0;
                }

                p {
                    padding: 0;
                    display: block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    > button {
                        @include transition();
                        position: static;
                        background: color(base, PrimaryButton);
                        width: 1.7rem;
                        height: 1.7rem;
                        border-radius: .5rem;
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        font-weight: 900;
                        margin-left: .5rem;
                        border: .2rem solid transparent;
                        font-size: 1.4rem;
                        line-height: .7;

                        @include hover() {
                            border-color: color(base, Black);
                        }
                    }
                }
            }
        }
    }
}
