._category_description_content {
    position: relative;

    &.no-image {
        margin-bottom: 2rem;

        h1 {
            position: relative;
            padding: 0 0 1.2rem 0;
            display: inline-block;
        }
    }

    h1 {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        background: color(base, White);
        padding: 1.2rem;

        &::before {
            content: '';
            height: 100%;
            width: 9.8rem;
            left: calc(100% - .1rem);
            bottom: 0;
            position: absolute;
            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 27.6.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 73.57 52.66' style='enable-background:new 0 0 73.57 52.66;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Cpath id='XMLID_00000130611214300967290780000013808037320987234688_' class='st0' d='M0,52.66h1.16c8.82,0,17.26-3.62,23.35-10.01 l34.73-36.5C62.97,2.22,68.15,0,73.57,0H0V52.66z'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-position: left;
            background-size: cover;
        }

        &::after {
            content: '';
            @include rev-counter();
            display: block;
            background-image: linear-gradient(90deg, #00a4e5, #00a4e5 100%, #e85121 0%, #bd1724 0%, #dadada 0, #dadada);
        }
    }
    @include breakpoint(small only) {
        .category__text {
            max-height: 17rem;
            overflow: hidden;
            margin-bottom: 1.6rem;
            &.--active{
                max-height: unset;
                overflow: visible;
                margin-bottom: 0;
                &:before{
                    content:none;
                }
            }
            &:before{
                background: linear-gradient(0deg, #fff, hsla(0, 0%, 100%, 0));
                content: "";
                height: 15rem;
                left: 0;
                position: absolute;
                bottom: 0;
                width: 100%;
            }
        }
        .--show-more{
            font-size:1.6rem;
            color: color(base, revBlue);
            position:relative;
            z-index: 1;
            display: block;
            margin: 0 auto 1.6rem;
        }
    }

    .image_container {
        position: relative;
        border-radius: 2.4rem;
        margin-bottom: 2.4rem;
        overflow: hidden;

        &::before {
            content: '';
            padding-top: 33%;
            display: block;
            @include breakpoint(small only) {
                padding-top: 60%;
            }
        }

        img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            left: 0;
            top: 0;
        }
    }

    p {
        font-size: 1.8rem;
        margin-bottom: 2.4rem;
    }
}

.catalog-category__toolbar {
    .toolbar__top {
        .--actions {
            display: flex;
            justify-content: space-between;
            font-size: 1.6rem;
        }
    }
}

.catalog__category-list {
    overflow: hidden;

    .--loading {
        z-index: 6;
    }

    > ul {
        margin: 0;
    }

    .grid-x.grid-padding-x {
        margin-right: -2rem;

        @include breakpoint(medium) {
            margin-right: -3rem;

            .cell {
                padding-left: 3rem;
                padding-right: 3rem;
            }
        }
    }

    .grid-x {
        border: none;

        .product__tile {
            width: 100%;
            height: 100%;
            border: none;
            display: block;
            padding-bottom: 0;
            margin-bottom: 0;

            @include breakpoint(medium) {
                margin-bottom: 3rem;
            }

            > a {
                display: block;
                position: relative;
                @include hover() {
                    ._product_title_container {
                        h3 {
                            &::after {
                                @include rev-counter-hover();
                            }
                        }
                    }

                    .image__holder {
                        img {
                            transform: scale(1.1);
                        }
                    }
                }
            }

            .bg-container {
                position: absolute;
                background-color: color(base, Gray);
                top: 4.6rem;
                bottom: 0;
                left: 0;
                right: 2rem;
                border-radius: 2.4rem;
                z-index: 0;

                @include breakpoint(medium) {
                    top: 7.6rem;
                    bottom: 3rem;
                }
            }

            .product-tile__container {
                position: relative;
                z-index: 2;
                display: flex;

                .content {
                    flex: 1;

                    @include breakpoint(medium) {
                        padding-top: 3rem;
                        padding-bottom: 3rem;
                    }


                    &-inner {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        height: 100%;
                    }

                    ._product_title_container {
                        position: relative;
                        background: color(base, White);
                        display: flex;
                        align-items: flex-end;
                        height: 9rem;

                        &::after {
                            content: '';
                            position: absolute;
                            right: -5.7rem;
                            height: 100%;
                            width: 5.7rem;
                            background-color: color(base, White);
                            background-image: url("data:image/svg+xml,%3Csvg width='74' height='53' viewBox='0 0 74 53' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_2_2)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M24.6533 42.9254C18.5277 49.3566 10.0383 53 1.16678 53H74L74 0H73.9827C71.292 0.00117024 68.6295 0.549283 66.1568 1.61115C63.6787 2.6753 61.4432 4.23312 59.5862 6.18971L24.6533 42.9254Z' fill='%23EDEDED'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_2_2'%3E%3Crect width='74' height='53' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
                            background-repeat: no-repeat;
                            background-position: left bottom;
                        }

                        h3 {
                            padding: 1rem;
                            color: color(base, DarkGray);
                            font-style: italic;
                            font-size: 2.8rem;
                            line-height: 1.1;
                            width: 100%;

                            @include breakpoint(small only) {
                                font-size: 1.8rem;
                            }

                            &::after {
                                content: '';
                                @include rev-counter();
                                display: block;
                            }
                        }
                    }

                    .product__holder {
                        position: relative;
                        flex: 1;
                        text-align: left;
                        padding: 0 0 5rem 2rem;
                        height: auto;
                        justify-content: flex-end;
                        min-height: 15rem;

                        @include breakpoint(medium) {
                            min-height: 20rem;
                        }

                        > *:first-child {
                            flex: 1;
                        }

                        ul {
                            @include transition();
                            opacity: .2;
                            margin: 1rem 0 0;

                            li {
                                text-align: left;
                                justify-content: flex-start;
                                align-items: baseline;
                                font-weight: bold;
                                font-style: italic;
                                padding: .2rem 0;

                                &::before {
                                    background: color(base, PrimaryButton);
                                    border-radius: .2rem;
                                    height: .6rem;
                                    flex: 0 0 .6rem;
                                    margin-top: .8rem;
                                }
                            }
                        }

                        .view__details {
                            text-align: left;

                            a {
                                @include transition();
                                font-size: 1.6rem;
                                background-color: color(base, PrimaryButton);
                                color: color(base, Black);
                                text-decoration: none;
                                display: inline-block;
                                padding: .4rem 3rem;
                                text-transform: uppercase;
                                border-radius: 1rem;
                                font-style: italic;

                                &:hover {
                                    background-color: color(base, PrimaryButtonHover);
                                }

                                &::after {
                                    display: none;
                                }

                                @include breakpoint(small only) {
                                    font-size: 1.2rem;
                                    padding: .4rem 1.5rem;
                                }
                            }
                        }

                        .price__holder {
                            position: absolute;
                            left: 2rem;
                            bottom: 1.5rem;

                            .price {
                                font-weight: normal;
                            }
                        }
                    }
                }

                .image__holder {
                    transition: all 1s ease .2s;
                    opacity: .6;
                    width: 60%;
                    margin: 0;
                    transform: scale(0.6);

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            .product__swatches {
                width: 70%;
                margin: 0 auto;

                .swiper-slide {
                    transition: opacity .5s ease-in-out, transform .5s ease-in-out;
                    opacity: .5;
                    transform: scale(.8);

                    > span {
                        display: block;
                        border-bottom: .2rem solid transparent;
                    }

                    &.swiper-slide-active {
                        opacity: 1;
                        transform: scale(1);

                        > span {
                            border-color: color(Gray, Gray-800);
                        }
                    }

                    &:not(.swiper-slide-active) span:hover {
                        border-color: transparent !important;
                    }
                }
            }
        }

        .--in-view {
            .product-tile__container {
                .content {
                    .product__holder ul {
                        opacity: 1;
                    }
                }

                .image__holder {
                    transform: scale(1);
                    opacity: 1;
                }
            }
        }
    }
}
