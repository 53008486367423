.form__input {
    position: relative;
    display: block;
    margin: 0 1.2rem;

    @include breakpoint(medium) {
        margin: 0 1.4rem;
    }

    input[type='text'],
    input[type='search'],
    input[type='date'],
    input[type='tel'],
    input[type='email'],
    input[type='number'],
    input[type='password'],
    select,
    textarea {
        border-radius: 0;
        display: flex;
        font-size: 1.5rem;
        background: color(base, White);
        text-transform: uppercase;
        color: color(base, DefaultFontColor);
        border-top: .1rem solid color(base, DefaultFontColor);
        border-bottom: .1rem solid color(base, DefaultFontColor);
        padding: .2rem .25rem 0;
        font-style: italic;
        font-weight: 700;
        border-left: none;
        border-right: none;
    }

    &::before {
        content: '';
        width: 1.2rem;
        position: absolute;
        right: 100%;
        top: 0;
        bottom: 0;
        background-image: url("data:image/svg+xml,%0A%3Csvg width='12px' height='34px' viewBox='0 0 12 34' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group' fill='%23FEFEFE' fill-rule='nonzero' stroke='%231E1E1E'%3E%3Cpath d='M156.157973,34 L11.6827,34 C10.1009036,33.9991988 8.6397662,33.1543315 7.84997272,31.7838182 L0.592518172,19.2130909 C-0.197506057,17.8434549 -0.197506057,16.1565451 0.592518172,14.7869091 L7.85151817,2.21309091 C8.64228714,0.844711189 10.1022644,0.00135202533 11.6827,0 L115.291518,0 C116.870973,0 118.332973,0.843818182 119.124245,2.21309091 L126.9767,15.8177273 C127.412518,16.5734545 128.135791,17.1205455 128.981155,17.3338182 L150.914245,22.899 C152.019046,23.186763 152.960844,23.9084372 153.526064,24.9003636 L157.442245,31.7930909 C157.701728,32.2501291 157.698887,32.8106062 157.434785,33.2649903 C157.170682,33.7193745 156.68508,33.9992551 156.159518,34' id='Path'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        background-size: cover;

        @include breakpoint(medium) {
            width: 1.4rem;
        }
    }

    &::after {
        content: '';
        width: 1.2rem;
        position: absolute;
        left: 100%;
        top: 0;
        bottom: 0;
        background-image: url("data:image/svg+xml,%0A%3Csvg width='12px' height='34px' viewBox='0 0 12 34' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group' transform='translate(6, 17) rotate(-180) translate(-6, -17)' fill='%23FEFEFE' fill-rule='nonzero' stroke='%231E1E1E'%3E%3Cpath d='M156.157973,34 L11.6827,34 C10.1009036,33.9991988 8.6397662,33.1543315 7.84997272,31.7838182 L0.592518172,19.2130909 C-0.197506057,17.8434549 -0.197506057,16.1565451 0.592518172,14.7869091 L7.85151817,2.21309091 C8.64228714,0.844711189 10.1022644,0.00135202533 11.6827,0 L115.291518,0 C116.870973,0 118.332973,0.843818182 119.124245,2.21309091 L126.9767,15.8177273 C127.412518,16.5734545 128.135791,17.1205455 128.981155,17.3338182 L150.914245,22.899 C152.019046,23.186763 152.960844,23.9084372 153.526064,24.9003636 L157.442245,31.7930909 C157.701728,32.2501291 157.698887,32.8106062 157.434785,33.2649903 C157.170682,33.7193745 156.68508,33.9992551 156.159518,34' id='Path'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        background-size: cover;

        @include breakpoint(medium) {
            width: 1.4rem;
        }
    }
}
