.pagination {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    @include breakpoint(small only) {
        padding-top: 3rem;
    }
    ul {
        display: flex;
        gap: .4rem;

        li {
            a, span {
                @include transition();
                @include border-radius();
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                line-height: 2.6rem;
                width: 2.6rem;
                height: 2.6rem;
                font-size: 1.6rem;
                font-weight: 900;
                font-style: italic;
                background: color(base, MediumGray);
                color: color(base, White);

                @include hover {
                    &.--bg {
                        background-color: transparent;
                    }

                    &::before {
                        display: none;
                    }

                    &:not(.--current):not(.--first):not(.--prev):not(.--next):not(.--last) {
                        background-color: color(base, Gray);
                        color: color(base, DarkGray);
                    }
                }

                &.--current {
                    background: color(base, White);
                    color: color(base, DarkGray);

                    &::before {
                        display: none;
                    }
                }

                &.--first {
                    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 27.6.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 22.69 23.41' style='enable-background:new 0 0 22.69 23.41;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23C6C6C5;%7D%0A%3C/style%3E%3Cpath class='st0' d='M19.05,0l3.25,0c0.36,0,0.52,0.74,0.24,1.11l-7.3,9.69c-0.35,0.46-0.35,1.34,0,1.8l7.3,9.69 c0.28,0.37,0.12,1.11-0.24,1.11h-3.25c-0.53,0-1.05-0.3-1.46-0.85l-6.58-8.74c-0.82-1.09-0.82-3.14,0-4.23l6.58-8.74 C18.01,0.3,18.52,0,19.05,0z'/%3E%3Cpath class='st0' d='M8.66,0l3.25,0c0.36,0,0.52,0.74,0.24,1.11l-7.3,9.69c-0.35,0.46-0.35,1.34,0,1.8l7.3,9.69 c0.28,0.37,0.12,1.11-0.24,1.11H8.66c-0.53,0-1.05-0.3-1.46-0.85l-6.58-8.74c-0.82-1.09-0.82-3.14,0-4.23l6.58-8.74 C7.61,0.3,8.12,0,8.66,0z'/%3E%3C/svg%3E%0A");
                    background-color: transparent;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 1.9rem;
                }

                &.--prev {
                    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 27.6.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 12.29 23.41' style='enable-background:new 0 0 12.29 23.41;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23C6C6C5;%7D%0A%3C/style%3E%3Cpath class='st0' d='M8.66,0l3.25,0c0.36,0,0.52,0.74,0.24,1.11l-7.3,9.69c-0.35,0.46-0.35,1.34,0,1.8l7.3,9.69 c0.28,0.37,0.12,1.11-0.24,1.11H8.66c-0.53,0-1.05-0.3-1.46-0.85l-6.58-8.74c-0.82-1.09-0.82-3.14,0-4.23l6.58-8.74 C7.61,0.3,8.12,0,8.66,0z'/%3E%3C/svg%3E%0A");
                    background-position: center;
                    background-repeat: no-repeat;
                    background-color: transparent;
                    background-size: 1rem;
                    width: 1rem;
                    margin-right: 1.1rem;
                }

                &.--next {
                    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 27.6.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 12.29 23.41' style='enable-background:new 0 0 12.29 23.41;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23C6C6C5;%7D%0A%3C/style%3E%3Cpath class='st0' d='M3.63,23.41H0.38c-0.36,0-0.52-0.74-0.24-1.11l7.3-9.69c0.35-0.46,0.35-1.34,0-1.8l-7.3-9.69 C-0.14,0.74,0.02,0,0.38,0l3.25,0c0.53,0,1.05,0.3,1.46,0.85l6.58,8.74c0.82,1.09,0.82,3.14,0,4.23l-6.58,8.74 C4.68,23.11,4.16,23.41,3.63,23.41z'/%3E%3C/svg%3E%0A");
                    background-position: center;
                    background-repeat: no-repeat;
                    background-color: transparent;
                    background-size: 1rem;
                    width: 1rem;
                    margin-left: 1.1rem;
                }

                &.--last {
                    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 27.6.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 22.69 23.41' style='enable-background:new 0 0 22.69 23.41;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23C6C6C5;%7D%0A%3C/style%3E%3Cpath class='st0' d='M3.63,23.41H0.38c-0.36,0-0.52-0.74-0.24-1.11l7.3-9.69c0.35-0.46,0.35-1.34,0-1.8l-7.3-9.69 C-0.14,0.74,0.02,0,0.38,0l3.25,0c0.53,0,1.05,0.3,1.46,0.85l6.58,8.74c0.82,1.09,0.82,3.14,0,4.23l-6.58,8.74 C4.68,23.11,4.16,23.41,3.63,23.41z'/%3E%3Cpath class='st0' d='M14.03,23.41h-3.25c-0.36,0-0.52-0.74-0.24-1.11l7.3-9.69c0.35-0.46,0.35-1.34,0-1.8l-7.3-9.69 C10.26,0.74,10.42,0,10.78,0l3.25,0c0.53,0,1.05,0.3,1.46,0.85l6.58,8.74c0.82,1.09,0.82,3.14,0,4.23l-6.58,8.74 C15.08,23.11,14.56,23.41,14.03,23.41z'/%3E%3C/svg%3E%0A");
                    background-color: transparent;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 1.9rem;
                }
            }
        }
    }
}
