@font-face {
    font-family: 'PF DinDisplay Pro';
    src: local('PFDinDisplayPro-Black'),
    url('/area/web/macna/assets/fonts/PFDinDisplayPro-Black.woff2') format('woff2'),
    url('/area/web/macna/assets/fonts/PFDinDisplayPro-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: fallback;
    ascent-override: 94%;
}

@font-face {
    font-family: 'PF DinDisplay Pro';
    src: local('PFDinDisplayPro-Bold'),
    url('/area/web/macna/assets/fonts/PFDinDisplayPro-Bold.woff2') format('woff2'),
    url('/area/web/macna/assets/fonts/PFDinDisplayPro-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: fallback;
    ascent-override: 94%;
}

@font-face {
    font-family: 'PF DinDisplay Pro';
    src: local('PFDinDisplayPro-BlackItalic'),
    url('/area/web/macna/assets/fonts/PFDinDisplayPro-BlackItalic.woff2') format('woff2'),
    url('/area/web/macna/assets/fonts/PFDinDisplayPro-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: fallback;
    ascent-override: 94%;
}

@font-face {
    font-family: 'PF DinDisplay Pro';
    src: local('PFDinDisplayPro-BoldItalic'),
    url('/area/web/macna/assets/fonts/PFDinDisplayPro-BoldItalic.woff2') format('woff2'),
    url('/area/web/macna/assets/fonts/PFDinDisplayPro-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: fallback;
    ascent-override: 94%;
}

@font-face {
    font-family: 'PF DinDisplay Pro';
    src: local('PFDinDisplayPro-Italic'),
    url('/area/web/macna/assets/fonts/PFDinDisplayPro-Italic.woff2') format('woff2'),
    url('/area/web/macna/assets/fonts/PFDinDisplayPro-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: fallback;
    ascent-override: 94%;
}

@font-face {
    font-family: 'PF DinDisplay Pro';
    src: local('PFDinDisplayPro-Light'),
    url('/area/web/macna/assets/fonts/PFDinDisplayPro-Light.woff2') format('woff2'),
    url('/area/web/macna/assets/fonts/PFDinDisplayPro-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: fallback;
    ascent-override: 94%;
}

@font-face {
    font-family: 'PF DinDisplay Pro';
    src: local('PFDinDisplayPro-LightItalic'),
    url('/area/web/macna/assets/fonts/PFDinDisplayPro-LightItalic.woff2') format('woff2'),
    url('/area/web/macna/assets/fonts/PFDinDisplayPro-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: fallback;
    ascent-override: 94%;
}

@font-face {
    font-family: 'PF DinDisplay Pro';
    src: local('PFDinDisplayPro-Medium'),
    url('/area/web/macna/assets/fonts/PFDinDisplayPro-Medium.woff2') format('woff2'),
    url('/area/web/macna/assets/fonts/PFDinDisplayPro-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: fallback;
    ascent-override: 94%;
}

@font-face {
    font-family: 'PF DinDisplay Pro';
    src: local('PFDinDisplayPro-MediumItalic'),
    url('/area/web/macna/assets/fonts/PFDinDisplayPro-MediumItalic.woff2') format('woff2'),
    url('/area/web/macna/assets/fonts/PFDinDisplayPro-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: fallback;
    ascent-override: 94%;
}

@font-face {
    font-family: 'PF DinDisplay Pro';
    src: local('PFDinDisplayPro-Regular'),
    url('/area/web/macna/assets/fonts/PFDinDisplayPro-Regular.woff2') format('woff2'),
    url('/area/web/macna/assets/fonts/PFDinDisplayPro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
    ascent-override: 94%;
}

@font-face {
    font-family: 'PF DinDisplay Pro';
    src: local('PFDinDisplayPro-Thin'),
    url('/area/web/macna/assets/fonts/PFDinDisplayPro-Thin.woff2') format('woff2'),
    url('/area/web/macna/assets/fonts/PFDinDisplayPro-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: fallback;
    ascent-override: 94%;
}

@font-face {
    font-family: 'PF DinDisplay Pro';
    src: local('PFDinDisplayPro-ThinItalic'),
    url('/area/web/macna/assets/fonts/PFDinDisplayPro-ThinItalic.woff2') format('woff2'),
    url('/area/web/macna/assets/fonts/PFDinDisplayPro-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: fallback;
    ascent-override: 94%;
}

@font-face {
    font-family: 'PF DinDisplay Pro ExtraThin';
    src: local('PFDinDisplayPro-ExtraThinItalic'),
    url('/area/web/macna/assets/fonts/PFDinDisplayPro-ExtraThinItalic.woff2') format('woff2'),
    url('/area/web/macna/assets/fonts/PFDinDisplayPro-ExtraThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: fallback;
    ascent-override: 94%;
}

@font-face {
    font-family: 'PF DinDisplay Pro ExtraThin';
    src: local('PFDinDisplayPro-ExtraThin'),
    url('/area/web/macna/assets/fonts/PFDinDisplayPro-ExtraThin.woff2') format('woff2'),
    url('/area/web/macna/assets/fonts/PFDinDisplayPro-ExtraThin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: fallback;
    ascent-override: 94%;
}
