.product__view .store-locator-section {
    display: flex;
    flex-wrap: wrap;
    position: relative;

    .vue-map-container {
        height: 20vh;
        min-height: 40rem;
        flex: 0 0 calc(100% + 4.8rem);
        order: 2;
        margin-left: -24px;
        margin-right: -24px;

        @include breakpoint(small only) {
            margin: 0;
            flex: 0 0 100%;
        }

        @include breakpoint(medium only) {
            margin-left: .6rem;
            margin-right: .6rem;
            flex: 0 0 calc(100% - 1.2rem);
        }

        .vue-map {
            border-radius: 0 0 15px 15px;
        }

        .--actions {
            a {
                display: block;
                color: color(base, Black);
                text-decoration: underline;
                font-size: 1.6rem;
                line-height: 2.2rem;
            }
        }
    }

    .store-locator__form {
        flex: 0 0 100%;
        order: 1;
        margin-bottom: 24px;
        margin-top: 60px;

        .--animation {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-top: 2rem;
            @include breakpoint(small only) {
                form {
                    width: 100%;
                }
            }

            h3 {
                position: absolute;
                top: -1px;
                left: 10%;
                background-color: #ededed;
                margin: 0 2.5rem;
                padding: 0.5rem 1rem;
                display: inline-block;
                color: color(base, DarkGray);
                font-style: italic;
                text-transform: uppercase;

                > span {
                    font-size: 3rem;
                    position: relative;
                    z-index: 4;
                    font-weight: normal;

                    &::after {
                        content: '';
                        display: block;
                        @include rev-counter();
                        margin-bottom: 0;
                    }

                    strong {
                        font-weight: 900;
                    }

                    @include breakpoint(small only) {
                        font-size: 2rem;
                    }
                }

                &::before,
                &::after {
                    content: '';
                    background-color: #ededed;
                    display: block;
                    position: absolute;
                    top: 0;
                    width: 80%;
                    height: 100%;
                    border: 0.195rem solid #1d1d1b;
                    border-top: 1px solid #ededed;

                }

                &::after {
                    left: -1.6rem;
                    transform: skew(30deg, 0deg);
                    border-bottom-left-radius: 10px;
                    border-right: 0;
                    z-index: 1;
                }

                &::before {
                    transform: skew(-30deg, 0deg);
                    right: -1.6rem;
                    border-left: 0;
                    border-bottom-right-radius: 10px;
                    z-index: 0;
                }
            }

            select {
                background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 28.09 28.09' style='enable-background:new 0 0 28.09 28.09' xml:space='preserve'%3E%3Cpath d='M20.4 0H7.7C3.45 0 0 3.45 0 7.7v12.7c0 4.25 3.45 7.7 7.7 7.7h12.7c4.25 0 7.7-3.45 7.7-7.7V7.7C28.09 3.45 24.65 0 20.4 0zm1.46 19.18c0 .31-.19.61-.55.86l-5.63 3.86c-.7.48-2.02.48-2.72 0l-5.63-3.86c-.35-.24-.55-.55-.55-.86v-1.91c0-.21.48-.3.72-.14l6.24 4.28c.3.2.86.2 1.16 0l6.24-4.28c.24-.16.72-.07.72.14v1.91zm0-8.19c0 .21-.48.3-.72.14L14.9 6.85c-.3-.2-.86-.2-1.16 0L7.5 11.13c-.24.16-.72.07-.72-.14V9.08c0-.31.19-.61.55-.86l5.63-3.86c.7-.48 2.02-.48 2.72 0l5.63 3.86c.35.24.55.55.55.86v1.91z' style='fill:%23c6c6c5'/%3E%3C/svg%3E");
                background-position: right 7px center;
                border: 2px solid color(base, MediumGray);
                border-radius: 5px;
                color: color(base, MediumGray);
                font-weight: 700;
                min-width: 0;
                padding-right: 36px;
                @include breakpoint(small only) {
                    width: 100%;
                    margin-bottom: 24px;
                }
            }

            .store__filter {
                margin-left: 48px;
                @include breakpoint(small only) {
                    margin-left: 0;
                }

                ul {
                    display: flex;

                    li {
                        padding: 0 6px;

                        input[type="checkbox"] {
                            display: none;
                        }

                        input[type="checkbox"]:checked + label {
                            opacity: 1;
                        }

                        label {
                            opacity: .5;
                            display: flex;
                            cursor: pointer;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column-reverse;
                            font-weight: 700;
                            font-style: italic;
                            font-size: 1.2rem;

                            img {
                                width: 30px;
                                margin-bottom: 0.2rem;
                            }
                        }
                    }
                }
            }
        }
    }
}
