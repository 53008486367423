@include breakpoint(medium) {
    #offCanvas-filter {
        margin: 1.5rem 0 0;
        background: rgba(255, 255, 255, .98);
        z-index: 2;
    }
}

@include breakpoint(small only) {
    #offCanvas-filter {
        width: 80%;
        transform: translateX(-80%);

        &.is-open {
            transform: translateX(0);
        }
    }
}

.catalog__category-filter {
    position: relative;

    @include breakpoint(medium) {
        max-width: 80%;
    }

    .--placeholder {
        display: flex;
        flex-wrap: wrap;

        span {
            display: block;
            height: 4rem;
            background-color: #F9F9F9;
        }
    }

    section {
        .modal__content {
            @include breakpoint(medium) {
                display: flex;
                flex-wrap: wrap;
            }

            .reset__filter {
                margin: 0 0 1rem;
            }

            .--sub-categories {
                flex: 0 0 100%;

                dd {
                    ol li {
                        a {
                            font-size: 1.6rem;
                            color: color(base, DefaultFontColor);
                            display: block;
                            padding: .3rem 0;

                            @include hover() {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }

            dl {
                flex: 0 0 100%;
                position: relative;

                dt {
                    font-size: 1.5rem;
                    cursor: pointer;
                    line-height: 4rem;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='4' stroke='#{color(base, PrimaryButton)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M19.5 8.25l-7.5 7.5-7.5-7.5' /%3E%3C/svg%3E%0A");
                    background-repeat: no-repeat;
                    background-size: 1.6rem;
                    background-position: right 1rem center;
                    font-weight: 700;
                    text-transform: uppercase;
                    border-bottom: .1rem solid color(gray, Gray-200);

                    @include breakpoint(small only) {
                        padding: 0 1rem;
                    }

                    &:not(.more__filters) {
                        background-color: color(base, White);
                    }

                    &.--visible:not(.more__filters) {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='4' stroke='#{color(base, PrimaryButton)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M4.5 15.75l7.5-7.5 7.5 7.5' /%3E%3C/svg%3E%0A");
                    }

                    &.more__filters {
                        text-transform: initial;
                        display: flex;
                        font-weight: normal;
                        border: none;
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(base, White)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75' /%3E%3C/svg%3E%0A");
                        background-size: 2.4rem;
                        line-height: 4rem;
                    }
                }

                dd {
                    @include breakpoint(small only) {
                        padding: 0 1rem;
                    }

                    ol {
                        margin: 1rem 0;

                        li label {
                            @include transition;
                            display: flex;
                            align-items: center;
                            cursor: pointer;
                            gap: 1rem;
                            padding: .7rem 0;
                            @include breakpoint(small only) {
                                padding: 0;
                            }

                            .--checkbox {
                                @include border-radius();
                                background: color(base, White);
                                width: 2rem;
                                height: 2rem;
                                border: 0.1rem solid color(gray, Gray-300);
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }

                            .--title {
                                flex: 1;
                                font-size: 1.3rem;
                                line-height: 2rem;

                                @include breakpoint(small only) {
                                    font-size: 1.3rem;
                                    line-height: 2.4rem;
                                }
                            }

                            .--count {
                                display: none;
                            }

                            &.--active {
                                .--checkbox {
                                    border-color: color(base, DefaultFontColor);
                                    border-width: .2rem;

                                    &::after {
                                        content: '';
                                        display: block;
                                        width: 1.2rem;
                                        height: 1.2rem;
                                        background-repeat: no-repeat;
                                        background-position: center;
                                        background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 12'%3E%3Cpath stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M1 5.917 5.724 10.5 15 1.5'/%3E%3C/svg%3E");
                                    }
                                }
                            }

                            @include hover() {
                                .--checkbox {
                                    border-color: color(base, DefaultFontColor);
                                }
                            }
                        }

                        &.--swatch {
                            display: grid;
                            grid-template-columns: repeat(6, 1fr);
                            gap: 1rem;

                            li {
                                label {
                                    width: 100%;
                                    aspect-ratio: 1;
                                    border-radius: 50%;
                                    border: .1rem solid color(gray, Gray-300);

                                    &.--active {
                                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'%3E%3Cg id='Group_1588' data-name='Group 1588' transform='translate(-0.409 -0.412)'%3E%3Crect id='Rectangle_229' data-name='Rectangle 229' width='18' height='18' transform='translate(0.409 0.412)' fill='none'/%3E%3Cpath id='Path_3310' data-name='Path 3310' d='M40,77.226l4.064,4.064L53.355,72' transform='translate(-36.978 -66.656)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E%0A");
                                        background-repeat: no-repeat;
                                        background-position: center;
                                        background-size: 65%;

                                        &.--light {
                                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'%3E%3Cg id='Group_1588' data-name='Group 1588' transform='translate(-0.409 -0.412)'%3E%3Crect id='Rectangle_229' data-name='Rectangle 229' width='18' height='18' transform='translate(0.409 0.412)' fill='none'/%3E%3Cpath id='Path_3310' data-name='Path 3310' d='M40,77.226l4.064,4.064L53.355,72' transform='translate(-36.978 -66.656)' fill='none' stroke='#{color(base, DefaultFontColor)}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E%0A");
                                        }
                                    }
                                }
                            }
                        }
                    }

                    > button {
                        margin: .5rem 0 1rem;
                        font-size: 1.3rem;
                        font-weight: 600;

                        @include hover() {
                            text-decoration: underline;
                        }
                    }
                }

                .price__filter {
                    padding: 1rem 1rem 1.5rem;

                    .vue-slider {
                        padding: .7rem .5rem !important;

                        .vue-slider-rail {
                            background: color(gray, Gray-150);
                            border-radius: 0;

                            .vue-slider-process {
                                background: color(gray, Gray-200);
                            }

                            .vue-slider-dot {
                                .vue-slider-dot-handle {
                                }
                            }
                        }

                        .vue-slider-dot-tooltip-inner {
                            background: color(base, PrimaryButton);
                            border-color: color(base, PrimaryButton);

                            .vue-slider-dot-tooltip-text {
                                color: color(base, White);
                                font-size: 1.2rem;
                            }
                        }
                    }

                    .custom__controls {
                        display: flex;
                        gap: 1rem;
                        margin: 3rem 0 1.5rem;

                        input[type='number'] {
                            width: 100%;
                        }
                    }

                    .--error {
                        color: color(base, Red);
                        font-size: 1.2rem;
                        margin: 1rem 0 0;
                    }
                }

                > button {
                    margin: 1rem 0 0;
                    width: 100%;
                    justify-content: center;
                }

                &.active__filter__container {
                    .filter__list {
                        button {
                            width: 100%;
                            font-size: 1.3rem;
                            padding: .75rem 2.5rem .75rem 1rem;
                            gap: .5rem;
                            display: flex;
                            align-items: center;

                            .--close {
                                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='4' stroke='#{color(base, PrimaryButton)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M6 18L18 6M6 6l12 12' /%3E%3C/svg%3E%0A");
                                flex: 0 0 1.2rem;
                                width: 1.2rem;
                                height: 1.2rem;
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }

    .modal__footer {
        padding: 1rem 1.5rem;
        border-top: .1rem solid color(gray, Gray-200);
        display: flex;
        gap: 2rem;
        background: color(base, White);

        button {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                color: color(base, White);
                font-size: 1.4rem;
            }
        }
    }
}

@include breakpoint(small only) {
    #offCanvas-filter {
        .modal__header {
            display: flex;
            border-bottom: .1rem solid color(gray, Gray-200);

            button {
                margin-left: auto;

                span {
                    display: block;
                    color: color(base, White);
                    font-size: 3.4rem;
                }
            }
        }
    }
}
